(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhAddMortgageModal', mhAddMortgageModal);
    function mhAddMortgageModal () {
        return {
            restrict: 'E',
            scope: { policyId: '<', type: '<' },
            templateUrl: 'js/modals/add-mortgage-modal/template.html',
            controller: function ($scope, $mdDialog, toaster, APIService, spinnerService) {
                $scope.forms = {};
                $scope.escrowFormData = {};
                $scope.cancel = function () { $mdDialog.cancel(); };

                $scope.addLender = function () {
                    const escrowForm = $scope.forms.escrow;
                    if (escrowForm.$valid) {
                        const mortgageCpy = _.merge({ billed: false, type: $scope.type }, $scope.escrowFormData);
                        const updateFormat = { [`new${Date.now()}`]: mortgageCpy };
                        const mortgageUpdate = {property_data: {mortgages: { details: updateFormat }}};
                        spinnerService.show('globalSpinner');
                        APIService.updatePolicyMortgages($scope.policyId, mortgageUpdate).then((updatedPolicy) => {
                            spinnerService.hide('globalSpinner');
                            toaster.pop('success', 'Mortgages', 'Saved');
                            $mdDialog.hide(updatedPolicy);
                        }).catch(err => {
                            spinnerService.hide('globalSpinner');
                            toaster.pop('error', 'Mortgages', err || 'Unable to save');
                        });
                    }
                };
            }
        };
    }
})(window.angular, window._);

